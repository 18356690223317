import CMSServices from "@/services/cms/cms.service";

export const cms = {
    namespaced: true,

    state: {},

    actions: {
        getProject({ commit }, data) {
            return CMSServices.getProject(data).then((data) => {
                commit("project", data);
                return Promise.resolve(data);
            });
        },
        getProjectSelect({ commit }, data) {
            return CMSServices.getProjectSelect(data).then((data) => {
                commit("project_select", data);
                return Promise.resolve(data);
            });
        },
        getIssue({ commit }, data) {
            return CMSServices.getIssue(data).then((data) => {
                commit("issue", data);
                return Promise.resolve(data);
            });
        },
        getIssueSelect({ commit }, data) {
            return CMSServices.getIssueSelect(data).then((data) => {
                commit("issue_select", data);
                return Promise.resolve(data);
            });
        },
        getChange({ commit }, data) {
            return CMSServices.getChange(data).then((data) => {
                commit("change", data);
                return Promise.resolve(data);
            });
        },
        getHistory({ commit }, data) {
            return CMSServices.getHistory(data).then((data) => {
                commit("change_history", data);
                return Promise.resolve(data);
            });
        },
        getResponse({ commit }, data) {
            return CMSServices.getResponse(data).then((data) => {
                commit("response", data);
                return Promise.resolve(data);
            });
        },
        getReplies({ commit }, data) {
            return CMSServices.getReplies(data).then((data) => {
                commit("replies", data);
                return Promise.resolve(data);
            });
        },
        getWorkflow({ commit }, data) {
            return CMSServices.getWorkflow(data).then((data) => {
                commit("workflow", data);
                return Promise.resolve(data);
            });
        },
    },
    mutations: {
        project(state, data) {
            state.project = data;
        },
        project_select(state, data) {
            state.project_select = data;
        },
        issue(state, data) {
            state.issue = data;
        },
        issue_select(state, data) {
            state.issue_select = data;
        },
        change(state, data) {
            state.change = data;
        },
        change_history(state, data) {
            state.change_history = data;
        },
        response(state, data) {
            state.response = data;
        },
        replies(state, data) {
            state.replies = data;
        },
        workflow(state, data) {
            state.workflow = data;
        },
    }
}