import axios from "axios";
import { _authHeader } from "@/services/auth._header";
import helpers from "@/helpers/helper";

const API_URL = helpers.API_ENDPOINT;

class CMSServices {
    // start: project
    getProject(data) {
        let data_check = data;
        if (data.saler_id == 0) {
            data_check.saler_id = '';
        } else {
            data_check.saler_id = data.saler_id;
        }
        if (data.priority == 'all') {
            data_check.priority = '';
        } else {
            data_check.priority = data.priority;
        }
        if (data.initiated_by == 0) {
            data_check.initiated_by = '';
        } else {
            data_check.initiated_by = data.initiated_by;
        }
        return axios.get(API_URL + "cms/project", { params: helpers.params(data_check), headers: _authHeader(), }).then((response) => {
            return response.data;
        });
    };
    getIssue(data) {
        let data_check = data;
        if (data.saler_id == 0) {
            data_check.saler_id = '';
        } else {
            data_check.saler_id = data.saler_id;
        }
        if (data.priority == 'all') {
            data_check.priority = '';
        } else {
            data_check.priority = data.priority;
        }
        if (data.initiated_by == 0) {
            data_check.initiated_by = '';
        } else {
            data_check.initiated_by = data.initiated_by;
        }
        return axios.get(API_URL + "cms/issues", { params: helpers.params(data_check), headers: _authHeader(), }).then((response) => {
            return response.data;
        });
    };
    getProjectSelect() {
        return axios.get(API_URL + "cms/project-select", { headers: _authHeader(), }).then((response) => {
            return response.data;
        });
    };
    getIssueSelect() {
        return axios.get(API_URL + "cms/issue-select", { headers: _authHeader(), }).then((response) => {
            return response.data;
        });
    };
    getChange(data) {
        let data_check = data;
        if (data.saler_id == 0) {
            data_check.saler_id = '';
        } else {
            data_check.saler_id = data.saler_id;
        }
        if (data.priority == 'all') {
            data_check.priority = '';
        } else {
            data_check.priority = data.priority;
        }
        if (data.initiated_by == 0) {
            data_check.initiated_by = '';
        } else {
            data_check.initiated_by = data.initiated_by;
        }
        return axios.get(API_URL + "cms/change", { params: helpers.params(data_check), headers: _authHeader(), }).then((response) => {
            return response.data;
        });
    };
    getHistory(data) {
        let data_check = data;
        if (data.saler_id == 0) {
            data_check.saler_id = '';
        } else {
            data_check.saler_id = data.saler_id;
        }
        if (data.priority == 'all') {
            data_check.priority = '';
        } else {
            data_check.priority = data.priority;
        }
        if (data.initiated_by == 0) {
            data_check.initiated_by = '';
        } else {
            data_check.initiated_by = data.initiated_by;
        }
        return axios.get(API_URL + "cms/change-history", { params: helpers.params(data_check), headers: _authHeader(), }).then((response) => {
            return response.data;
        });
    };
    // getStatus() {
    //     return axios.get(API_URL + "help_desk/t_status", {
    //         headers: _authHeader(),
    //     });
    // };
    getResponse(data) {
        let data_check = data;
        if (data.saler_id == 0) {
            data_check.saler_id = '';
        } else {
            data_check.saler_id = data.saler_id;
        }
        if (data.priority == 'all') {
            data_check.priority = '';
        } else {
            data_check.priority = data.priority;
        }
        return axios.get(API_URL + "cms/change-comment", { params: helpers.params(data_check), headers: _authHeader(), }).then((response) => {
            return response.data;
        });
    };
    getReplies(data) {
        let data_check = data;
        if (data.saler_id == 0) {
            data_check.saler_id = '';
        } else {
            data_check.saler_id = data.saler_id;
        }
        if (data.priority == 'all') {
            data_check.priority = '';
        } else {
            data_check.priority = data.priority;
        }
        return axios.get(API_URL + "cms/change-comment-reply", { params: helpers.params(data_check), headers: _authHeader(), }).then((response) => {
            return response;
        });
    };
    getWorkflow(data) {
        let data_check = data;
        if (data.saler_id == 0) {
            data_check.saler_id = '';
        } else {
            data_check.saler_id = data.saler_id;
        }
        if (data.priority == 'all') {
            data_check.priority = '';
        } else {
            data_check.priority = data.priority;
        }
        if (data.initiated_by == 0) {
            data_check.initiated_by = '';
        } else {
            data_check.initiated_by = data.initiated_by;
        }
        return axios.get(API_URL + "cms/workflow", { params: helpers.params(data_check), headers: _authHeader(), }).then((response) => {
            return response.data;
        });
    };
    // end: project

    // getClient(data) {
    //     return axios.post(API_URL + "help_desk/client-select-dropdown", data, {
    //         headers: _authHeader(),
    //     });
    // };
    // getCategory(data) {
    //     return axios.get(API_URL + "help_desk/t_category", { headers: _authHeader(), }).then((response) => {
    //         return response.data;
    //     });
    // };


    // getResponse(data) {
    //     let data_check = data;
    //     if (data.saler_id == 0) {
    //         data_check.saler_id = '';
    //     } else {
    //         data_check.saler_id = data.saler_id;
    //     }
    //     if (data.priority == 'all') {
    //         data_check.priority = '';
    //     } else {
    //         data_check.priority = data.priority;
    //     }
    //     return axios.get(API_URL + "help_desk/t_response", { params: helpers.params(data_check), headers: _authHeader(), }).then((response) => {
    //         return response.data;
    //     });
    // };
    // getReplies(data) {
    //     let data_check = data;
    //     if (data.saler_id == 0) {
    //         data_check.saler_id = '';
    //     } else {
    //         data_check.saler_id = data.saler_id;
    //     }
    //     if (data.priority == 'all') {
    //         data_check.priority = '';
    //     } else {
    //         data_check.priority = data.priority;
    //     }
    //     return axios.get(API_URL + "help_desk/t_reply", { params: helpers.params(data_check), headers: _authHeader(), }).then((response) => {
    //         return response;
    //     });
    // };
}

export default new CMSServices();