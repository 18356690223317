import moment from "moment" //Not yet installed

let apiEndpoints = "";
switch (process.env.VUE_APP_ENV) {

    case 'local_eve':
        apiEndpoints = "http://127.0.0.1:8000/api/";
        break;
    case 'local_nelson':
        apiEndpoints = "http://127.0.0.1/disposals/zemo-ideal-customer-platform/api/public/api/";
        break;
    case 'test':
        apiEndpoints = "https://icptst.zemocard.com/api/";
        break;
    default:
        apiEndpoints = "https://icpapi.zemocard.com/api/";
        break;
}
const API_ENDPOINT = apiEndpoints
const BASE_URL = "https://api.zemocard.com/";
const copyright = new Date().getFullYear() + " " + "Melin Software Solutions Ltd. All Right Reserved";
const version = "Version: 1.0.3";
// const API_ENDPOINT_ALT = "https://apicpanel.zemocard.com/api/v2/";
const API_ENDPOINT_ALT = "https://adminapi.zemocard.com/api/v2/";


function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}
function getRandomString(length) {
    var randomChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var result = "";
    for (var i = 0; i < length; i++) {
        result += randomChars.charAt(
            Math.floor(Math.random() * randomChars.length)
        );
    }
    return result;
}
function getInitials(str) {
    return str
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), "");
}
function currencyFormat(amount, decimal = 2) {
    return Number(amount).toLocaleString(undefined, {
        minimumFractionDigits: decimal,
    });
}
function formatNumber(value) {
    return Number(value).toLocaleString('en-US');
}
function percentile(funded_wallets, empty_wallets) {
    let percentile = (funded_wallets / empty_wallets) * 100;
    return (percentile).toLocaleString(undefined, { maximumFractionDigits: 2 });
}
function dateFormat(date) {
    return moment(date).format("Do MMM, YYYY hh:mm A");
}
function dateFormatYear(date) {
    return moment(date).format("YYYY");
}
// formatPaymentsStatus
function dateFormatD() {
    const today = new Date();
    return moment(today).format('Do MMM, YYYY hh:mm A');
}
function formatPaymentsStatus(value) {
    if (value == 0) {
        return "fails and refunded";
    } else if (value == 1) {
        return "Completed";
    } else if (value == 2) {
        return "Rejected";
    } if (value == 3) {
        return "Reversed";
    } else {
        return "Cancelled";
    }
}
function formatKeyStatus(value) {
    switch (true) {
        case value == 2:
            return "Inactive";
        case value == 1:
        default:
            return "Active";
    }
}
function formatClientStatus(value) {
    switch (true) {
        case value == 0:
            return "In Active";
        case value == 1:
            return "Active";
        default:
            return "Prospect";
    }
}
function formatTaskStatus(value) {
    switch (true) {
        case value == 0:
            return "In Progress";
        case value == 1:
            return "Done";
        default:
            return "TBA";
    }
}
//start new check
function formatVisit(value, created_by, assigned_to, status) {
    if (value == 1) {
        return '<span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>';
    } else {
        return '<span class="text-info">Waiting</span>';
    }
}
function formatDemo(value, created_by, assigned_to, status) {
    if (value == 1 && status == 4 || status == 5 || status == 6 || status == 7 || status == 8) {
        return '<span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>';
    } else if (value == 0 && status == 1 || status == 2 | status == 3) {
        return '<span class="text-warning">In progress</span>';
    } else {
        return "-";
    }
}
function formatSale(value, created_by, assigned_to, status) {
    if (value == 0 && status == 1 || status == 2 || status == 3 || status == 4) {
        return '<span class="text-warning">In progress</span>';
    } else if (value == 0 && status == 5) {
        return '<span class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></span>';
    } else if (value == 1 && status == 6 || status == 7 || status == 8) {
        return '<span class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>';
    } else {
        return "-";
    }
}
function formatSaleStatus(value, created_by, assigned_to, status) {
    if (value == 1) {
        return '<span class="text-info">Lead in</span>';
    } else if (value == 2) {
        return '<span class="text-primary">Contacted</span>';
    } else if (value == 3) {
        return '<span class="text-warning">Demo</span>';
    } else if (value == 4) {
        return '<span class="text-warning">Need defined</span>';
    } else if (value == 5) {
        return '<span class="text-danger">Lost</span>';
    } else if (value == 6) {
        return '<span class="text-success">Worn</span>';
    } else if (value == 7) {
        return '<span class="text-success">Nurturing</span>';
    } else if (value == 8) {
        return '<span class="text-success">Nurturing</span>';
    } else {
        return "-";
    }
}
function formatTicketStatus(value) {
    if (value.id == 1) {
        return '<span class="text-info">' + value.name + '</span>';
    }
    else if (value.id == 2) {
        return '<span class="text-primary">' + value.name + ' ' + '<i class="fa fa-list-ol text-primary" aria-hidden="true"></i>' + '</span>';
    }
    else if (value.id == 3) {
        return '<span class="text-warning">' + value.name + ' ' + '<i class="fa fa-star text-success" aria-hidden="true"></i>' + '</span>';
    }
    else if (value.id == 4) {
        return '<span class="text-danger">' + value.name + '</span>';
    }
    else if (value.id == 5) {
        return '<span class="text-success">' + value.name + ' ' + '<i class="fa fa-list text-check" aria-hidden="true"></i>' + '</span>';
    }
    else if (value.id == 6) {
        return '<span class="text-success">' + value.name + ' ' + '<i class="fa fa-check" aria-hidden="true"></i>' + '</span>';
    }
    else {
        return "-";
    }
}

function formatPriorityStatus(value) {
    if (value == 'Low') {
        return '<span class="text-warning">' + value + ' ' + '</span>';
    }
    else if (value == 'Medium') {
        return '<span class="text-primary">' + value + ' ' + '</span>';
    }
    else if (value == 'High') {
        return '<span class="text-success">' + value + ' ' + '</span>';
    }
    else {
        return "-";
    }
}
function formatAmount(row, col, value, index) {
    return Number(value ? value : 0).toLocaleString(undefined, {
        minimumFractionDigits: 2,
    });
}
function formatTimestamp(row, col, value, index) {
    var timestamp = Date.parse(value);
    if (isNaN(timestamp) == false) {
        var d = new Date(timestamp);
        const hour = d.getHours();
        const minute = d.getMinutes();
        if (hour === 12 && minute === 0) {
            return moment(d).format("DD/MM/YYYY") + ' ' + 'Noon';
        } else if (hour === 0 && minute === 0) {
            return moment(d).format("DD/MM/YYYY") + ' ' + 'Midnight';
        }
        else {
            return moment(d).format("DD/MM/YYYY hh:mmA");
        }
    }
}
function formatDate(data) {
    var timestamp = Date.parse(data);
    if (isNaN(timestamp) == false) {
        var d = new Date(timestamp);
        return moment(d).format("YYYY/MM/DD");
    }
}
function formatDatePayroll(row, col, value, index) {
    var timestamp = Date.parse(value);
    if (isNaN(timestamp) == false) {
        var d = new Date(timestamp);
        return moment(d).format("DD/MM/YYYY");
    }
}
function formatYear(data) {
    var timestamp = Date.parse(data);
    var d = new Date(timestamp);
    return moment(d).format("YYYY")
}
function capitalizeFirstLetter(string) {
    var initial = string.replaceAll('_', ' ').trim();
    const fn = initial.trim().split(/\s+/);
    var name = fn[0];
    return name.charAt(0).toUpperCase() + name.slice(1);
}
function dateToday() {
    var today = new Date().toLocaleString();
    return moment(today).format("DD/MM/YYYY hh:mmA");
}
function filterDate(date) {
    var timestamp = Date.parse(date);
    if (isNaN(timestamp) == false) {
        var d = new Date(timestamp);
        return moment(d).format("YYYY-MM-DD");
    }
}
function formatMonth(value) {
    return moment(value).format("MMM YYYY");
}
function greetings(data) {
    const strfn = data;
    const fn = strfn.trim().split(/\s+/);
    return fn[0];
}
function formartbBlog(value) {
    if (value == 1) {
        return "Public"
    } else if (value == 0) {
        return "Private"
    }
}
function params(data) {
    const params = new URLSearchParams();
    params.append('page', data.page ?? 1);
    params.append('size', data.size);
    params.append('search', data.search ?? null);
    params.append('start_date', data.start_date != null ? data.start_date : '');
    params.append('end_date', data.end_date != null ? data.end_date : '');
    //deal additionals
    params.append('download', data.download ?? '');
    params.append('saler_id', data.saler_id ?? '');
    params.append('stage', data.stage ?? '');
    params.append('status', data.status ?? "");
    //tasks, notes and emails
    params.append('deal_id', data.deal_id ?? "");
    //ticketing
    params.append('priority', data.priority ?? '');
    params.append('initiated_by', data.initiated_by ?? '');
    // response
    params.append('t_ticket_id', data.t_ticket_id ?? '');
    //replies
    params.append('t_response_id', data.t_response_id ?? "");
    //cms
    params.append('project_id', data.project_id ?? "");
    params.append('change_id', data.change_id ?? "");
    params.append('change_type', data.change_type ?? "");
    params.append('submitted_by_user_id', data.submitted_by_user_id ?? "");
    params.append('reviewer', data.reviewer ?? "");

    return params;
}
function emailCheck(status) {
    if (status === 1) {
        return 'Sent';
    } else {
        return 'Not Sent';
    }
}
function formatDealStatus(value) {
    if (value == 1) {
        return 'Active';
    } else if (value == 0) {
        return 'Inactive';
    } else {
        return '-';
    }
}
function formatDealStages(value) {
    if (value == 1) {
        return '<span class="text-info">Lead In</span>';
    } else if (value == 2) {
        return '<span class="text-primary">Contact made</span>';
    } else if (value == 3) {
        return '<span class="text-warning">Demo scheduled</span>';
    } else if (value == 4) {
        return '<span class="text-warning">Needs Defined</span>';
    } else if (value == 5) {
        return '<span class="text-warning">Negotiation</span>';
    } else if (value == 6) {
        return '<span class="text-danger">Lost</span>';
    } else if (value == 7) {
        return '<span class="text-success">Won</span>';
    } else if (value == 8) {
        return '<span class="text-success">Affiliate</span>';
    } else if (value == 9) {
        return '<span class="text-success">Nurturing</span>';
    } else {
        return '-';
    }
}
function capitalize(value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
}
function maskEmail(email) {
    const [name, domain] = email.split("@");
    const maskedName = name.slice(0, 3) + "****";
    const maskedDomain =
        domain.charAt(0) + "**" + domain.slice(domain.indexOf("."));
    return `${maskedName}@${maskedDomain}`;
}
//end new checks

function checkfigure(value) {
    let cleanfigure = value
    return cleanfigure = cleanfigure.replace(/[^A-Za-z0-9]/g, '');
}
function formatApprovalStatus(value) {
    if (value == 1) {
        return "Approved";
    } else if (value == 2) {
        return "Rejected";
    } else if (value == 3) {
        return "Refunded";
    } else if (value === 4) {
        return "No Approval";
    } else {
        return "Pending Approval";
    }
}
function formatDemoStatus(value) {
    if (!value || value.id === 1) {
        return "-";
    }

    switch (value.id) {
        case 2:
            return `<span class="text-primary">${value.name}</span>`;
        case 3:
            return `<span class="text-danger">${value.name}</span>`;
        case 4:
            return `<span class="text-success">${value.name}</span>`;
        default:
            return `<span>${value.name}</span>`;
    }
}

export default {
    API_ENDPOINT,
    API_ENDPOINT_ALT,
    BASE_URL,
    copyright,
    version,
    getBase64,
    getRandomString,
    getInitials,
    currencyFormat,
    formatNumber,
    percentile,
    dateFormat,
    dateFormatYear,
    dateFormatD,
    formatPaymentsStatus,
    formatKeyStatus,
    formatClientStatus,
    formatTaskStatus,
    //start: new chack
    formatVisit,
    formatDemo,
    formatSale,
    formatSaleStatus,
    formatTicketStatus,
    formatPriorityStatus,
    formatAmount,
    formatTimestamp,
    formatDate,
    formatDatePayroll,
    formatYear,
    capitalizeFirstLetter,
    dateToday,
    filterDate,
    formatMonth,
    greetings,
    checkfigure,
    formatApprovalStatus,
    formartbBlog,
    params,
    emailCheck,
    formatDealStatus,
    formatDealStages,
    capitalize,
    maskEmail,
    formatDemoStatus,
    //end: new chack
}

